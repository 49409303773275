<template>
  <div ref="tableRootRef" class="table-container" :id="id">
    <div v-if="!noHeader" :style="calcGrid(headers)" class="headers">
      <template v-for="header in headers">
        <div
          v-if="!ignoreData.includes(header.key)"
          :key="header.key"
          class="item"
        >
          <slot :name="`header-${header.key}`" :value="header">
            <span
              class="content"
              :id="generateId(header.label, header.key)"
              ref="textContainerRef"
              v-tooltip="
                isOverflowing(generateId(header.label, header.key))
                  ? {
                      content: header.label,
                      delay: { show: 800, hide: 100 },
                    }
                  : null
              "
            >
              {{ $t('tables.headers.' + header.label) }}
            </span>
          </slot>
          <span v-if="sortColumns.includes(header.key)" class="sorting">
            <ButtonElement @click="$emit('sort-asc', header.key)" link>
              <Arrow
                class="sort-asc"
                :class="{
                  'sort-asc--active': sortType === `${header.key}:asc`,
                }"
                direction="top"
                width="20px"
                height="20px"
              />
            </ButtonElement>
            <ButtonElement @click="$emit('sort-desc', header.key)" link>
              <ArrowIcon
                class="sort-desc"
                :class="{
                  'sort-desc--active': sortType === `${header.key}:desc`,
                }"
                direction="bottom"
                width="20px"
                height="20px"
              />
            </ButtonElement>
          </span>
        </div>
      </template>
    </div>
    <div
      v-for="row in data"
      :key="row.id"
      :class="row"
      :style="calcGrid(Object.entries(row))"
      class="table-row m"
      @click="$emit('row-click', row)"
    >
      <template v-if="rowValidator">
        <div class="overlay" v-if="rowDisabled(row)"></div>
      </template>
      <template v-for="(cell, key) in row">
        <div
          v-if="!ignoreData.includes(key)"
          :key="key"
          class="item"
          :class="{ 'right-align': rightAlign.includes(key) }"
        >
          <div class="d-block">
            <slot :name="`cell-${key}`" :value="cell" :header="key" :row="row">
              <!-- only primitive types are accepted, if reference types, must use slots -->
              <span
                v-if="allowedTypes(cell)"
                class="content"
                :id="generateId(cell, key)"
                ref="textContainerRef"
                v-tooltip="
                  isOverflowing(generateId(cell, key))
                    ? {
                        content: cell,
                        delay: { show: 800, hide: 100 },
                      }
                    : null
                "
              >
                {{ cell }}
              </span>
            </slot>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import useOverflowText from '../../composables/useOverflowText';
import { convertKeysToHeaders } from '../../utils/helpers';
import ButtonElement from '../FormControl/ButtonElement.vue';
import ArrowIcon from '../Svgs/ArrowIcon.vue';

export default {
  setup(props, ctx) {
    const [isOverflowing, textContainerRef] = useOverflowText();

    const tableRootRef = ref(null);

    return {
      isOverflowing,
      textContainerRef,
      tableRootRef,
    };
  },
  components: {
    ArrowIcon,
    ButtonElement,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    sortType: {
      type: String,
      default: '',
    },
    sortColumns: {
      type: Array,
      default: () => [],
    },
    columnWidth: {
      type: Array,
      default: () => [
        // { width: '750px', key: 'recipients', fixed: true },
        // { key: 'reportUrl', width: '450px', fixed: false },
      ],
      validator: val =>
        val.length > 0 ? val.every(x => x.width && x.key) : true,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    rightAlign: {
      type: Array,
      default: () => [],
    },
    ignoreData: {
      type: Array,
      default: () => [],
    },
    rowValidator: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    headers() {
      if (this.data.length > 0) {
        const headerKeys = Object.keys(this.data[0]);
        return headerKeys.map(item => ({
          key: item,
          label: convertKeysToHeaders(item),
        }));
      }

      return [];
    },
  },
  methods: {
    allowedTypes(cell) {
      const allowedTypes = ['string', 'number', 'boolean'];
      return allowedTypes.includes(typeof cell);
    },
    generateId(cell, key) {
      if (typeof cell !== 'string' && this.allowedTypes(cell))
        cell = String(cell);
      return `${key}-${this.id}-${cell.slice(0, 10).toLowerCase()}__text`;
    },
    rowDisabled(row) {
      return eval(this.rowValidator);
    },
    calcGrid(valuesArray) {
      valuesArray = valuesArray.filter(
        i => !this.ignoreData.includes(i.key || i[0])
      );

      return {
        display: 'grid',
        'grid-template-columns': valuesArray
          .map(item => {
            let width = '200px';
            if (this.columnWidth.length > 0) {
              const result = this.columnWidth.find(x => {
                return item.key === x.key || item[0] === x.key;
              });

              if (result?.width) {
                return result.fixed
                  ? result.width
                  : `minmax(${result.width}, 1fr)`;
              }
            }
            return `minmax(${width}, 1fr)`;
          })
          .join(' '),
        'grid-template-rows': `minmax(60px, auto)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  overflow-x: auto;
  width: 100%;
  height: 100%;
}

.headers {
  overflow-wrap: break-word;
  // background-color: white;
  height: 40px;
  position: sticky;
  top: 0;

  > .item {
    background-color: #fbeee2;
    color: $hty-orange;
    height: 40px;
    border-bottom: 0;
    display: flex;
    align-items: center;

    &:first-child {
      border-top-left-radius: $hty-radius;
    }
    &:last-child {
      border-top-right-radius: $hty-radius;
    }
  }
}

.item {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  height: 60px;
}

.content {
  @include hty-ellipsis;
}

.table-row {
  position: relative;
  overflow-wrap: break-word;
  background-color: white;
  cursor: pointer;
  // overflow-y: auto;
  // height: 100%;
  .link {
    small {
      color: #bfbfbf;
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    background: rgba(246, 246, 246, 0.85);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &:nth-child(odd) {
    > .item {
      background-color: #fafafa;
    }
  }
  &:nth-child(even) {
    > .item {
      background-color: #f4f4f4;
    }
  }

  .right-align {
    justify-content: flex-end;
  }
}

.sorting {
  position: relative;
  top: 4px;
  padding-left: 5px;

  .sort-asc--active {
    ::v-deep path {
      stroke: $hty-black;
    }
  }
  .sort-desc--active {
    ::v-deep path {
      stroke: $hty-black;
    }
  }
}
</style>
