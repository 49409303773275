var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableRootRef",staticClass:"table-container",attrs:{"id":_vm.id}},[(!_vm.noHeader)?_c('div',{staticClass:"headers",style:(_vm.calcGrid(_vm.headers))},[_vm._l((_vm.headers),function(header){return [(!_vm.ignoreData.includes(header.key))?_c('div',{key:header.key,staticClass:"item"},[_vm._t(("header-" + (header.key)),[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              _vm.isOverflowing(_vm.generateId(header.label, header.key))
                ? {
                    content: header.label,
                    delay: { show: 800, hide: 100 },
                  }
                : null
            ),expression:"\n              isOverflowing(generateId(header.label, header.key))\n                ? {\n                    content: header.label,\n                    delay: { show: 800, hide: 100 },\n                  }\n                : null\n            "}],ref:"textContainerRef",refInFor:true,staticClass:"content",attrs:{"id":_vm.generateId(header.label, header.key)}},[_vm._v(" "+_vm._s(_vm.$t('tables.headers.' + header.label))+" ")])],{"value":header}),(_vm.sortColumns.includes(header.key))?_c('span',{staticClass:"sorting"},[_c('ButtonElement',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('sort-asc', header.key)}}},[_c('Arrow',{staticClass:"sort-asc",class:{
                'sort-asc--active': _vm.sortType === ((header.key) + ":asc"),
              },attrs:{"direction":"top","width":"20px","height":"20px"}})],1),_c('ButtonElement',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('sort-desc', header.key)}}},[_c('ArrowIcon',{staticClass:"sort-desc",class:{
                'sort-desc--active': _vm.sortType === ((header.key) + ":desc"),
              },attrs:{"direction":"bottom","width":"20px","height":"20px"}})],1)],1):_vm._e()],2):_vm._e()]})],2):_vm._e(),_vm._l((_vm.data),function(row){return _c('div',{key:row.id,staticClass:"table-row m",class:row,style:(_vm.calcGrid(Object.entries(row))),on:{"click":function($event){return _vm.$emit('row-click', row)}}},[(_vm.rowValidator)?[(_vm.rowDisabled(row))?_c('div',{staticClass:"overlay"}):_vm._e()]:_vm._e(),_vm._l((row),function(cell,key){return [(!_vm.ignoreData.includes(key))?_c('div',{key:key,staticClass:"item",class:{ 'right-align': _vm.rightAlign.includes(key) }},[_c('div',{staticClass:"d-block"},[_vm._t(("cell-" + key),[(_vm.allowedTypes(cell))?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.isOverflowing(_vm.generateId(cell, key))
                  ? {
                      content: cell,
                      delay: { show: 800, hide: 100 },
                    }
                  : null
              ),expression:"\n                isOverflowing(generateId(cell, key))\n                  ? {\n                      content: cell,\n                      delay: { show: 800, hide: 100 },\n                    }\n                  : null\n              "}],ref:"textContainerRef",refInFor:true,staticClass:"content",attrs:{"id":_vm.generateId(cell, key)}},[_vm._v(" "+_vm._s(cell)+" ")]):_vm._e()],{"value":cell,"header":key,"row":row})],2)]):_vm._e()]})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }