import { ref } from '@vue/composition-api';

export default function useOverflowText() {
  const overflowRef = ref();

  function isOverflowing(id) {
    if (id) {
      // provide id if using on element inside v-for since it will be in unordered array
      // so we need to find proper element
      if (overflowRef.value) {
        const item = overflowRef.value.find(item => item.id === id);
        if (item?.scrollWidth > item?.clientWidth) {
          return true;
        }
        return false;
      }
    } else {
      // if no id argument is provided, that means single element is applied to ref
      if (overflowRef.value?.scrollWidth > overflowRef.value?.clientWidth) {
        return true;
      }
      return false;
    }
  }

  return [isOverflowing, overflowRef];
}
